<template>
  <TypeOfExistingRadiatorFormField :choices="choices" v-on="$listeners" />
</template>

<script>
import { Selectable } from 'chimera/all/components/models/Selectable'
import TypeOfExistingRadiatorFormField from 'chimera/all/components/form/fields/typeOfExistingRadiator/TypeOfExistingRadiatorFormField.vue'

export default {
  name: 'TypeOfExistingRadiatorFormPart',

  components: {
    TypeOfExistingRadiatorFormField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      choices: [
        new Selectable(
          this.$i18n.t(
            'field.type-of-existing-radiator.options.low-temperature-radiator',
          ),
          this.$i18n.t(
            'field.type-of-existing-radiator.options.low-temperature-radiator',
          ),
          this.$i18n.t(
            'field.type-of-existing-radiator.options.low-temperature-radiator',
          ),
          {
            groupHelp: this.$i18n.t(
              'field.type-of-existing-radiator.options.low-temperature-radiator-description',
            ),
          },
        ),
        new Selectable(
          this.$i18n.t(
            'field.type-of-existing-radiator.options.regular-radiator',
          ),
          this.$i18n.t(
            'field.type-of-existing-radiator.options.regular-radiator',
          ),
          this.$i18n.t(
            'field.type-of-existing-radiator.options.regular-radiator',
          ),
          {
            groupHelp: this.$i18n.t(
              'field.type-of-existing-radiator.options.regular-radiator-description',
            ),
          },
        ),
      ],
    }
  },
}
</script>
