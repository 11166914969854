<template>
  <IntendsToReplaceRadiatorFormField :choices="choices" v-on="$listeners" />
</template>

<script>
import { Selectable } from 'chimera/all/components/models/Selectable'
import IntendsToReplaceRadiatorFormField from 'chimera/all/components/form/fields/intendsToReplaceRadiator/IntendsToReplaceRadiatorFormField.vue'

export default {
  name: 'IntendsToReplaceRadiatorFormPart',

  components: {
    IntendsToReplaceRadiatorFormField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      choices: [
        new Selectable(
          this.$i18n.t('global.yes'),
          this.$i18n.t('global.yes'),
          this.$i18n.t('global.yes'),
        ),
        new Selectable(
          this.$i18n.t('global.no'),
          this.$i18n.t('global.no'),
          this.$i18n.t('global.no'),
          {
            groupHelp: this.$i18n.t(
              'field.intends-to-replace-radiator.options.description',
            ),
          },
        ),
      ],
    }
  },
}
</script>
