<template>
  <FormModal
    title="Welk type verwarming wordt op de warmtepomp aangesloten?"
    show-back-btn
  >
    <template #body>
      <TypeOfHeatingFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import TypeOfHeatingFormStep from '~/components/form/steps/typeOfHeating/TypeOfHeatingFormStep.vue'

export default {
  components: {
    TypeOfHeatingFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Type verwarming',
      headDescription:
        'Geef aan naar wat voor type warmtepomp installatie je op zoek bent.',
      path: '/offertes-aanvragen/type-verwarming',
      progressValue: 45,
      checkoutStep: 3,
    }
  },
}
</script>
